<script>
import * as jdenticon from 'jdenticon';
import { truncate } from '../../utils.js';

export let cardTitle;
export let identicon = true;
export let noZoom = false;
</script>

<div class="{!noZoom ? 'card is-clickable' : 'card no-zoom'}">
  {#if identicon}
    {@html jdenticon.toSvg(cardTitle, 60)}
  {/if}
  <div class="card-title module">
    <span>{truncate(cardTitle, { length: 25 })}</span>
  </div>

  <slot />
</div>
