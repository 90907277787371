<style global lang="scss">
@import './stylesheets/config.scss';
</style>

<script>
import Router from 'svelte-spa-router';

import { eth } from './subeth';
import { organizations } from './stores/organizations.js';
import { routes } from './routes/index.js';
import { addAccount, updateSDKSigner } from './stores/sync.js';
import Debugger from './components/Debugger.svelte';
import Loading from './components/Loading.svelte';

const { status } = organizations;

$: updateSDKSigner($eth);
$: addAccount($eth);
</script>

<div class="container.is-fullhd">
  <Loading loading="{$status.loading}" />
  <Router routes="{routes}" />
  {#if window.location.host.match(/localhost/)}
    <Debugger />
  {/if}
</div>
