<script>
import Footer from '../components/Footer.svelte';
import Header from '../components/Header.svelte';
import Hero from '../components/landing/Hero.svelte';
import What from '../components/landing/What.svelte';

export let params = {};
</script>

<Header params="{params}" />

<div class="fade-in">
  <Hero />
  <What />
</div>

<Footer params="{params}" />
