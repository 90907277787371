<script>
export let params = {};
export let sdk = {};

// imports
import { _ } from 'svelte-i18n';
import moment from 'moment';
import snapshot from '@snapshot-labs/snapshot.js';
import { writable } from 'svelte/store';

import { eth } from '../../../../subeth/index.js';
import { shortenAddress } from '@pie-dao/utils';
import { truncate } from '../../../../utils.js';
import { getProposals, getVoteResults, publish } from '../../../../snapshot';
import { voteMessage } from '../../../../snapshot/message.js';
import { go, VoteFilters } from '../../../../routes/index.js';
import AddressBar from '../../../shared/AddressBar.svelte';

const state = writable({ proposals: [] });

// images
const vote = 'images/vote-large.svg';
// these are proposals from our testing
const PROPOSAL_FILTERS = [
  'QmawfckicSGgY76gnYryktVDBLPn5FawJPvFUFHY9Fyjcm',
  'QmNMioUoincTar7TDtegLU7Po8WjALp4cVz5q9fdbYDoju',
  'QmbhUFyp7ktMwryJwodgei5sH2JqnUdKgc34sR9881ztRE',
  'QmU9Jkr5J6wsjVQf39euTqdfQLCNnN9HaBDAY6Gxx1iV5x',
  'QmSS13ogpBZ1CQyixcQDeVp4Wa7Wrm3YiZKCXrnhTuKaEW',
];

let buildingProposals = false;
let filteredProposals;
let proposalsFilter = 'all';
let publishing = false;
let voteExpanded = {};

// on init
const buildProposals = async () => {
  buildingProposals = true;

  const proposalsResults = await getProposals('edao.eth');
  let formattedProposals = Object.keys(proposalsResults)
    .map((key) => {
      proposalsResults[key].id = key;
      return proposalsResults[key];
    })
    .filter(
      (formattedProposal) => !PROPOSAL_FILTERS.includes(formattedProposal.id),
    );

  for (let i = 0; i < formattedProposals.length; i += 1) {
    const formattedProposal = formattedProposals[i];
    formattedProposal.votes = await getVoteResults(
      formattedProposal.msg.space,
      formattedProposal.id,
    );

    const voters = Object.keys(formattedProposal.votes);

    if (voters.length) {
      const scores = await snapshot.utils.getScores(
        formattedProposal.msg.space,
        formattedProposal.msg.payload.metadata.strategies,
        '1',
        snapshot.utils.getProvider('1'),
        voters,
        formattedProposal.msg.payload.snapshot,
      );

      for (let j = 0; j < scores.length; j += 1) {
        for (const [key, value] of Object.entries(scores[j])) {
          formattedProposal.yesLambda = 0;
          formattedProposal.noLambda = 0;
          formattedProposal.abstainLambda = 0;

          for(let proposalVoteKey in formattedProposal.votes) {
            if (key === proposalVoteKey && value <= 0) {
              delete formattedProposal.votes[proposalVoteKey];
            }
          }

          const votes = Object.values(formattedProposal.votes);

          for (let k = 0; k < votes.length; k += 1) {
            const vote = votes[k];

            switch (vote.msg.payload.choice) {
              case 1:
                  formattedProposal.yesLambda += 1;
                break;
              case 2:
                  formattedProposal.noLambda += 1;
                break;
              case 3:
                  formattedProposal.abstainLambda += 1;
                break;
            }

            if(vote.address === key) {
              vote.votableShares = value;
            }
          }
        }
      }
    }
  }

  state.set({ proposals: formattedProposals });
  buildingProposals = false;
};
buildProposals();

// functions
const changeVoteFilter = (filter, proposals) => {
  proposalsFilter = filter;

  switch (filter) {
    case 'all':
      filteredProposals = proposals;
      break;
    case 'active':
      filteredProposals = proposals.filter((vote) => {
        return isActive(vote);
      });
      break;
    case 'pending':
      filteredProposals = proposals.filter((vote) => {
        return isPending(vote);
      });
      break;
    case 'closed':
      filteredProposals = proposals.filter((vote) => {
        return isClosed(vote);
      });
      break;
  }
};

const isActive = (vote) => {
  const currentTimestamp = Date.now() / 1000;

  return (
    vote.msg.payload.start < currentTimestamp &&
    currentTimestamp < vote.msg.payload.end
  );
};

const isClosed = (vote) => {
  const currentTimestamp = Date.now() / 1000;

  return currentTimestamp > vote.msg.payload.end;
};

const isPending = (vote) => {
  const currentTimestamp = Date.now() / 1000;

  return vote.msg.payload.start > currentTimestamp;
};

const publishVote = async (proposal, choice) => {
  // 1 = Yes
  // 2 = No
  // 3 = Abstain
  if (choice > 3 || choice < 1) {
    console.error(
      'error',
      'ElasticDAO: Not a valid vote choice(1: Yes, 2: No, 3: Abstain',
    );
    return;
  }

  publishing = true;

  let msg = voteMessage;
  msg.timestamp = Math.floor(new Date().getTime() / 1000).toString();
  msg.payload.choice = choice;
  msg.payload.proposal = proposal.id;
  const signedMessage = await $eth.signer.signMessage(JSON.stringify(msg));

  await publish({
    address: $eth.address,
    msg: JSON.stringify(msg),
    sig: signedMessage,
  }).catch(() => (publishing = false));

  publishing = false;
};

const toggleExpansion = (voteId) => {
  voteExpanded[voteId] = !voteExpanded[voteId];
};

$: changeVoteFilter(VoteFilters.All, $state.proposals);
</script>

<section class="section fade-in">
  <div class="section-header">
    <h1>{$_('Dashboard.Snapshot_Votes')}</h1>

    <button
      class="button action-button"
      on:click="{() => go(...params.path, { action: 'new' })}"
    >
      <i
        class="{publishing ? 'fas fa-spinner fa-spin mr-3' : 'fas fa-poll mr-3'}"
      ></i>{$_('Dashboard.new_vote')}
    </button>
  </div>
  {#if buildingProposals}
    <div class="has-text-centered mt-8">
      <i class="fas fa-spinner fa-spin fa-4x font white"></i>
    </div>
  {/if}

  {#if $state.proposals && $state.proposals.length === 0}
    {#if !buildingProposals}
      <div class="has-text-centered mt-8">
        <img src="{vote}" alt="no votes created yet" />
        <h2>
          {$_('Dashboard.There_have_not_been_any_informational_votes_yet')}
        </h2>
      </div>
    {/if}
  {:else if $state.proposals}
    <div class="filters">
      <div
        class="filter {proposalsFilter === 'all' && 'is-active'}"
        on:click="{() => changeVoteFilter(VoteFilters.All, $state.proposals)}"
      >
        {$_('Dashboard.All')}
      </div>

      <div
        class="filter {proposalsFilter === 'active' && 'is-active'}"
        on:click="{() => changeVoteFilter(VoteFilters.Active, $state.proposals)}"
      >
        {$_('Dashboard.Active')}
      </div>
      <div
        class="filter {proposalsFilter === 'pending' && 'is-active'}"
        on:click="{() => changeVoteFilter(VoteFilters.Pending, $state.proposals)}"
      >
        {$_('Dashboard.Pending')}
      </div>
      <div
        class="filter {proposalsFilter === 'closed' && 'is-active'}"
        on:click="{() => changeVoteFilter(VoteFilters.Closed, $state.proposals)}"
      >
        {$_('Dashboard.Closed')}
      </div>
    </div>

    <div class="votes">
      {#each filteredProposals as proposal}
        <div
          id="{proposal.id}"
          class="vote is-clickable"
          on:click="{() => toggleExpansion(proposal.id)}"
        >
          <div class="columns">
            <div class="column pb-0">
              <div class="vote-status">
                <div
                  class="{(isActive(proposal) && 'mr-3 pill active') || (isPending(proposal) && 'mr-3 pill pending') || (isClosed(proposal) && 'mr-3 pill closed')}"
                >
                  {isActive(proposal) ? 'Active' : isClosed(proposal) ? 'Closed' : isPending(proposal) && 'Pending'}
                </div>
                <div class="vote-info">
                  <p>
                    <a
                      href="{`https://cloudflare-ipfs.com/ipfs/${proposal.id}`}"
                      target="_blank"
                      rel="noopener"
                    >
                      {truncate(proposal.id, { length: 10 })}
                    </a>:
                    {truncate(proposal.msg.payload.name, { length: 25 })}
                  </p>
                  <p class="vote-created-by">
                    {$_('Dashboard.Created_By')}:
                    <a
                      href="{`https://etherscan.com/address/${proposal.address}`}"
                    >{shortenAddress(proposal.address)}</a>
                  </p>
                </div>
              </div>
            </div>

            <div class="column pb-0">
              <div class="columns">
                <div class="column">
                  <div class="vote-record">
                    <h5>{$_('Dashboard.Yes')}({proposal.yesLambda || 0})</h5>
                    <progress
                      class="progress yes"
                      value="{proposal.yesLambda || 0}"
                      max="{proposal.votes.length}"
                    >
                      {$_('Dashboard.yes')}
                    </progress>
                  </div>
                </div>

                <div class="column">
                  <div class="vote-record">
                    <h5>{$_('Dashboard.No')}({proposal.noLambda || 0})</h5>
                    <progress
                      class="progress no"
                      value="{proposal.noLambda || 0}"
                      max="{proposal.votes.length}"
                    >
                      {$_('Dashboard.no')}
                    </progress>
                  </div>
                </div>

                <div class="column">
                  <div class="vote-record">
                    <h5>
                      {$_('Dashboard.Abstain')}({proposal.abstainLambda || 0})
                    </h5>
                    <progress
                      class="progress abstain"
                      value="{proposal.abstainLambda || 0}"
                      max="{proposal.votes.length}"
                    >
                      {$_('Dashboard.abstain')}
                    </progress>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="{voteExpanded[proposal.id] ? 'vote-content expanded' : 'vote-content'}"
          >
            <div class="columns is-multiline">
              <div class="column is-full">
                <h5>{$_('Dashboard.Proposal')}</h5>
                <p>{proposal.msg.payload.body}</p>
              </div>

              <div class="column is-one-quarter is-mobile">
                <h5>{$_('Dashboard.Start_Date')}</h5>
                <p>
                  {new Date(proposal.msg.payload.start * 1000).toLocaleDateString()}
                  {#if isPending(proposal)}
                    <span
                    >({moment
                        .unix(proposal.msg.payload.start)
                        .fromNow()})</span>
                  {/if}
                </p>
              </div>

              <div class="column is-one-quarter is-mobile">
                <h5>{$_('Dashboard.End_Date')}</h5>
                <p>
                  {new Date(proposal.msg.payload.end * 1000).toLocaleDateString()}
                  <span
                    class="{isClosed(proposal) ? 'highlight' : ''}"
                  >({moment.unix(proposal.msg.payload.end).fromNow()})</span>
                </p>
              </div>

              <div class="column is-one-quarter is-mobile">
                <h5>{$_('NewVote.Snapshot_Block')}</h5>
                <p>{proposal.msg.payload.snapshot}</p>
              </div>

              <!-- <div class="column is-mobile is-one-quarter">
                <h5>{$_('Dashboard.Reached_Quorum')}</h5>
                <p class="{vote.hasReachedQuorum ? 'success' : 'fail'}">
                  {vote.hasReachedQuorum}
                </p>
              </div> -->

              <!-- {#if !vote.isActive}
                <div class="column is-mobile is-one-quarter">
                  <h5>{$_('Dashboard.Approved')}</h5>
                  <p class="{vote.isApproved ? 'success' : 'fail'}">
                    {vote.isApproved}
                  </p>
                </div>
              {/if} -->

              <div class="column is-full">
                <h5>{$_('Dashboard.Cast_Ballot')}</h5>
                <button
                  on:click|stopPropagation="{() => publishVote(proposal, 1)}"
                  class="button action-button yes mr-5"
                  disabled="{!$eth.address || isClosed(proposal) || isPending(proposal)}"
                ><i
                    class="fas fa-vote-yea mr-3"
                  ></i>{$_('Dashboard.Yes')}</button>
                <button
                  on:click|stopPropagation="{() => publishVote(proposal, 2)}"
                  class="button action-button no mr-5"
                  disabled="{!$eth.address || isClosed(proposal) || isPending(proposal)}"
                ><i
                    class="fas fa-vote-nay mr-3"
                  ></i>{$_('Dashboard.No')}</button>
                <button
                  on:click|stopPropagation="{() => publishVote(proposal, 3)}"
                  class="button action-button abstain mr-5"
                  disabled="{!$eth.address || isClosed(proposal) || isPending(proposal)}"
                ><i
                    class="fas fa-box-ballot mr-3"
                  ></i>{$_('Dashboard.Abstain')}</button>
              </div>
            </div>

            {#if Object.keys(proposal.votes).length > 0}
              <div class="columns is-multiline">
                <div class="column is-full is-mobile">
                  <section class="section mt-3 p-5 mb-0 overflow">
                    <div class="section-header mb-1">
                      <h1>{$_('Dashboard.Results')}</h1>
                    </div>

                    <table class="table">
                      <thead>
                        <tr>
                          <th>
                            <abbr title="Voter">{$_('Dashboard.Voter')}</abbr>
                          </th>
                          <th>
                            <abbr
                              title="Result"
                            >{$_('Dashboard.Vote_Result')}</abbr>
                          </th>
                          <th>
                            <abbr
                              title="Voting Power"
                            >{$_('Dashboard.Votable_Shares')}</abbr>
                          </th>
                          <th>
                            <abbr
                              title="Receipt"
                            >{$_('Dashboard.Receipt')}</abbr>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {#each Object.values(proposal.votes) as vote}
                          <tr>
                            <td
                              class="is-flex is-justify-content-center is-clickable"
                            >
                              <a
                                on:click|stopPropagation
                                href="{`https://etherscan.com/address/${vote.address}`}"
                                target="_blank"
                                rel="noopener"
                              >
                                <AddressBar
                                  address="{vote.address.toLowerCase()}"
                                />
                              </a>
                            </td>
                            <td>
                              {vote.msg.payload.choice === 1 ? $_('Dashboard.Yes') : vote.msg.payload.choice === 2 ? $_('Dashboard.No') : $_('Dashboard.Abstain')}
                            </td>
                            <td>{vote.votableShares}</td>
                            <td>
                              <a
                                on:click|stopPropagation
                                href="{`https://cloudflare-ipfs.com/ipfs/${vote.relayerIpfsHash}`}"
                                target="_blank"
                                rel="noopener"
                              >
                                <i class="fas fa-passport" title="relayer"></i>
                              </a>
                              <a
                                on:click|stopPropagation
                                href="{`https://cloudflare-ipfs.com/ipfs/${vote.authorIpfsHash}`}"
                                target="_blank"
                                rel="noopener"
                              >
                                <i class="fas fa-receipt" title="author"></i>
                              </a>
                            </td>
                          </tr>
                        {/each}
                      </tbody>
                    </table>
                  </section>
                </div>
              </div>
            {/if}
          </div>
        </div>
      {/each}
    </div>
  {/if}
</section>
