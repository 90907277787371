<script>
import {
  changeDashboardTab,
  changeOrganizationSettingsTab,
  OrganizationSettingsTabs,
} from '../routes/index.js';

import Footer from '../components/Footer.svelte';
import Header from '../components/Header.svelte';
import DAO from '../components/client/settings/DAO.svelte';
import Summoning from '../components/client/settings/Summoning.svelte';
import { _ } from 'svelte-i18n';

export let params = {};
</script>

<Header params="{params}" />

<div class="organization-settings">
  <div class="container is-max-desktop">
    <div class="tabs is-max-desktop">
      <ul>
        <li>
          <button
            on:click="{() => changeDashboardTab(params.id, 'home')}"
          >{$_('OrgSettings.Home')}</button>
        </li>
        <li
          class="{(!params.tab || params.tab === OrganizationSettingsTabs.DAO) && 'is-active'}"
        >
          <button
            on:click="{() => changeOrganizationSettingsTab(params.id, OrganizationSettingsTabs.DAO)}"
          >DAO</button>
        </li>
        <li
          class="{params.tab === OrganizationSettingsTabs.Summoning && 'is-active'}"
        >
          <button
            on:click="{() => changeOrganizationSettingsTab(params.id, OrganizationSettingsTabs.Summoning)}"
          >{$_('OrgSettings.Summoning')}</button>
        </li>
      </ul>
    </div>

    <div class="tabs-content">
      {#if !params.tab || params.tab === OrganizationSettingsTabs.DAO}
        <DAO params="{params}" />
      {/if}
      {#if params.tab === OrganizationSettingsTabs.Summoning}
        <Summoning params="{params}" />
      {/if}
    </div>
  </div>
</div>

<Footer params="{params}" />
