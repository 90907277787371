<script>
import Footer from '../components/Footer.svelte';
import Header from '../components/Header.svelte';
import Navigation from '../components/client/organizations/Navigation.svelte';
import TabContent from '../components/client/organizations/TabContent.svelte';

export let params = {};
</script>

<Header params="{params}" />

<div class="organization">
  <div class="container is-max-desktop">
    <Navigation params="{params}" />
    <TabContent params="{params}" />
  </div>
</div>

<Footer params="{params}" />
