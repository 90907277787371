<script>
export let params = {};

import { t, lambdaFromT, capitalDelta } from '@elastic-dao/sdk';

import { amountFormatter } from '../../../utils.js';
import { balances, key } from '../../../stores/balances.js';
import { changeDashboardTab, DashboardTabs } from '../../../routes/index.js';
import { liveOrganizations } from '../../../stores/organizations.js';
import BigNumber from 'bignumber.js';
import { ethers } from 'ethers';

let exitAmount = 0;
let exiting = false;

const { account, keys } = liveOrganizations;

$: keySet = $keys[params.id];
$: dao = liveOrganizations[keySet.daoStoreKey];
$: token = liveOrganizations[keySet.tokenStoreKey];

$: deltaT = BigNumber(exitAmount);
$: deltaT = deltaT.isNaN() ? BigNumber(0) : deltaT;
$: tValue = t($token.lambda, $token.m, $token.k);
$: e = $balances[key($dao.uuid)];
$: capitalDeltaValue = capitalDelta(e, tValue);
$: deltaE = deltaT.multipliedBy(capitalDeltaValue);
$: accountTBalance = $account.balances.find(
  ({ address }) => address.toLowerCase() === $token.uuid.toLowerCase(),
);
$: accountTAmount = accountTBalance ? accountTBalance.amount : BigNumber(0);

const exit = async () => {
  if (deltaT === 0) {
    return;
  }

  exiting = true;

  const deltaLambda = lambdaFromT(deltaT, $token.m, $token.k);

  await dao.elasticDAO
    .exit(BigNumber(deltaLambda))
    .catch(() => (exiting = false));
  exiting = false;
  changeDashboardTab(params.id, DashboardTabs.Finances);
};

const jump = (percentage = 1) => {
  exitAmount = accountTAmount.multipliedBy(percentage).toFixed();
};
</script>

<section class="section fade-in">
  <div class="section-header">
    <h1>Exit {$dao.name}</h1>

    <button
      class="button action-button"
      disabled="{deltaT === 0}"
      on:click="{exit}"
    >
      <i
        class="{exiting ? 'fas fa-spinner fa-spin mr-3' : 'fab fa-ethereum mr-3'}"
      ></i>exit
    </button>
  </div>

  <div class="level">
    <div class="level-item has-centered-text">
      <p class="heading">Exit Amount</p>
      <p class="title">{amountFormatter({ amount: deltaT })} {$token.symbol}</p>
    </div>

    <div class="level-item has-centered-text">
      <p class="heading">ETH Received</p>
      <p class="title">
        >=
        {amountFormatter({
          amount: deltaE,
          approximatePrefix: '',
          lessThanPrefix: '',
        })}
        <i class="fab fa-ethereum"></i>
      </p>
    </div>

    <div class="level-item has-centered-text">
      <p class="heading">Your Balance</p>
      <p class="title">
        {amountFormatter({ amount: accountTAmount })}
        {$token.symbol}
      </p>
    </div>

    <div class="level-item has-centered-text">
      <p class="heading">Vault Value</p>
      <p class="title">
        {amountFormatter({ amount: e })}
        <i class="fab fa-ethereum"></i>
      </p>
    </div>
  </div>

  <div class="columns is-multiline slide-controller">
    <div class="column is-half is-mobile is-offset-one-quarter-tablet">
      <div class="is-flex is-align-items-center is-justify-content-center">
        <button
          class="button action-button mx-3"
          disabled="{$account.address === ethers.constants.AddressZero}"
          on:click="{() => jump(0.25)}"
        >
          25%
        </button>
        <button
          class="button action-button mx-3"
          disabled="{$account.address === ethers.constants.AddressZero}"
          on:click="{() => jump(0.5)}"
        >
          50%
        </button>
        <button
          class="button action-button mx-3"
          disabled="{$account.address === ethers.constants.AddressZero}"
          on:click="{() => jump(0.75)}"
        >
          75%
        </button>
        <button
          class="button action-button mx-3"
          disabled="{$account.address === ethers.constants.AddressZero}"
          on:click="{() => jump()}"
        >
          100%
        </button>
      </div>

      <div class="is-flex is-align-items-center is-justify-content-center mt-5">
        <input
          type="range"
          min="0"
          step="0.000001"
          max="{accountTAmount.toFixed()}"
          bind:value="{exitAmount}"
        />

        <input
          class="purchase-lambda"
          type="number"
          min="0"
          step="0.000001"
          max="{accountTAmount.toFixed()}"
          bind:value="{exitAmount}"
        />
      </div>
    </div>
  </div>
</section>
