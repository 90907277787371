// NOTE: The goal is to replace this functionality with an
// on chain registry at some point in this nearish future

import SnapshotVoteModuleController from '../components/client/modules/SnapshotVoteModule/Controller.svelte';

export const determineModuleComponent = ({ moduleName }) => {
  if (moduleName === 'SnapshotVoteModule') {
    return SnapshotVoteModuleController;
  }

  throw new Error(
    `elasticdao.org - routes/modules.js: Unknown module name '${moduleName}'`,
  );
};

export const loadModuleData = () => ({});

export default {
  determineModuleComponent,
};
