import { addMessages, init, getLocaleFromNavigator } from 'svelte-i18n';

// German
import de from './components/Internationalization/locales/de.json';
// English
import en from './components/Internationalization/locales/en.json';
// Spanish
import es from './components/Internationalization/locales/es.json';
// French
import fr from './components/Internationalization/locales/fr.json';
// Italian
import it from './components/Internationalization/locales/it.json';
// Korean
import ko from './components/Internationalization/locales/ko.json';
// Russian
import ru from './components/Internationalization/locales/ru.json';
// Chinese
import zhTW from './components/Internationalization/locales/zh-TW.json';

// German
addMessages('de', de);
// English
addMessages('en', en);
// Spanish
addMessages('es', es);
// French
addMessages('fr', fr);
// Italian
addMessages('it', it);
// Korean
addMessages('ko', ko);
// Russian
addMessages('ru', ru);
// Chinese
addMessages('zhTW', zhTW);

init({
  fallbackLocale: 'en',
  initialLocale: getLocaleFromNavigator(),
});
