import { ethers } from 'ethers';
import { get } from 'svelte/store';
import { shortenAddress } from '@pie-dao/utils';
import * as jdenticon from 'jdenticon';

import { defaultEth, eth } from './writables';
import { bumpLifecycle, updateCurrentBlock } from './lifecycle';

import { resetContractCache } from './contracts';

let conditionalProvider = ethers.getDefaultProvider();

// ENV_NETWORK is replace by rollup, so can be safely ignored by eslint
// eslint-disable-next-line no-undef
if (ENV_NETWORK === 'localhost') {
  conditionalProvider = new ethers.providers.JsonRpcProvider(
    'http://localhost:8545',
  );
}

// ENV_NETWORK is replace by rollup, so can be safely ignored by eslint
// eslint-disable-next-line no-undef
if (ENV_NETWORK === 'edaoTestnet') {
  conditionalProvider = new ethers.providers.JsonRpcProvider(
    'https://node.edao.app',
  );
}

export const defaultProvider = conditionalProvider;

defaultProvider.on('block', updateCurrentBlock);

eth.set({ ...get(eth), provider: defaultProvider });

// const connectWeb3 = () => registerConnection();

const resetWeb3Listeners = () => {
  const { provider } = get(eth);

  if (provider) {
    provider.off('block', updateCurrentBlock);
    defaultProvider.on('block', updateCurrentBlock);
  }
};

const setWeb3Listeners = () => {
  const { provider } = get(eth);

  if (provider) {
    defaultProvider.off('block', updateCurrentBlock);
    provider.on('block', updateCurrentBlock);
  }
};

export const registerConnection = async (newWeb3) => {
  const web3 = newWeb3 || get(eth).web3;

  if (!web3) {
    throw new Error('Unable to find a web3 object. Was one passed?');
  }

  const provider = new ethers.providers.Web3Provider(web3);
  const signer = provider.getSigner();

  const [currentBlockNumber, network, address] = await Promise.all([
    provider.getBlockNumber(),
    provider.getNetwork(),
    signer.getAddress(),
  ]);

  window.localStorage.setItem('address', address);

  const shortAddress = shortenAddress(address);

  const icon = jdenticon.toSvg(address, 16);

  eth.set({
    address,
    currentBlockNumber,
    icon,
    network,
    provider,
    shortAddress,
    signer,
    web3,
  });

  setWeb3Listeners();
  bumpLifecycle();
};

export const resetConnection = () => {
  resetWeb3Listeners();
  resetContractCache();
  eth.set({ ...defaultEth, provider: defaultProvider });
};
