import { writable } from 'svelte/store';

export const defaultEth = {
  address: undefined,
  icon: undefined,
  network: {},
  provider: undefined,
  shortAddress: '',
  signer: undefined,
  balance: undefined,
};

export const allowances = writable({});
export const balances = writable({});
export const eth = writable({ ...defaultEth, currentBlockNumber: 0 });
