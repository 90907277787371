<script>
import { ethers } from 'ethers';
import { get } from 'svelte/store';

import { balances } from '../stores/balances.js';
import { eth } from '../subeth';
import { liveOrganizations } from '../stores/organizations.js';
import sdk from '../config/sdk.js';

window.ethers = ethers;
window.get = get;
window.liveOrganizations = liveOrganizations;
window.sdk = sdk;

const { keys } = liveOrganizations;

$: window.balances = $balances;
$: window.eth = $eth;
$: window.keys = $keys;
</script>

<!-- debugging mode enabled -->
