<script>
import sdk from '../config/sdk';
import { connectWeb3, eth } from '../subeth';
import {
  changeOrganizationSettingsTab,
  OrganizationSettingsTabs,
} from '../routes/index.js';
import {
  addSummoner,
  formatForDeploy,
  organization,
  removeSummoner,
  validForDeploy,
} from '../stores/organization.js';
import { loadAllOrganizations } from '../stores/sync';
import Footer from '../components/Footer.svelte';
import Header from '../components/Header.svelte';
import { _ } from 'svelte-i18n';

export let params = {};

const defaultInfoMessage = $_('Create.Create_your_own_organization');
const subdomainMessage = $_('Create.subdomainMessage');
const organizationNameMessage = $_('Create.organizationNameMessage');
const tokenNameMessage = $_('Create.tokenNameMessage');
const tokenSymbolMessage = $_('Create.tokenSymbolMessage');
const summonerMessage = $_('Create.summonerMessage');
let infoMessage = defaultInfoMessage;
let defaultMessage = true;

// Images
const rocket = 'images/rocket.svg';

const create = async () => {
  if (!$eth.signer) {
    await connectWeb3();
    const pid = setInterval(() => {
      if ($eth.signer) {
        clearInterval(pid);
        create();
      }
    }, 100);
    return;
  }

  const payload = formatForDeploy();
  const dao = await sdk.elasticDAOFactory.deployDAOAndToken(...payload);

  loadAllOrganizations();

  changeOrganizationSettingsTab(dao.uuid, OrganizationSettingsTabs.Summoning);
};

const refreshSummoners = () => {
  $organization.summoners = Array.from($organization.summoners);
};

const setInfoMessage = (_message) => {
  defaultMessage = false;
  infoMessage = _message;
};
</script>

<Header params="{params}" />

<div class="create fade-in">
  <div class="container is-max-desktop">
    <div class="notification mt-3">
      <i class="fas fa-info-circle"></i>
      {$_('Create.fee')}
    </div>

    <section class="section">
      <div class="section-header">
        <h1>{$_('Create.Create_an_Organization')}</h1>

        <button
          class="button action-button"
          on:click="{create}"
          disabled="{!validForDeploy($organization)}"
        >
          <i class="fas fa-rocket"></i>
          {$_('Create.launch')}
        </button>
      </div>

      <div class="columns">
        <div class="column is-mobile is-hidden-tablet">
          {#if defaultMessage}
            <p class="blurb" class:hidden="{!defaultMessage}">
              {@html infoMessage}
            </p>
          {:else}
            <p class="blurb" class:hidden="{defaultMessage}">
              {@html infoMessage}
            </p>
          {/if}

          <img
            class="blurb-illustration is-hidden-mobile"
            src="{rocket}"
            alt="create organization"
          />
        </div>
      </div>

      <div class="columns">
        <div class="column is-one-quarter-desktop is-mobile">
          <div class="section-content">
            <h5>{$_('Create.Organization_Name')}</h5>
            <div class="control is-expanded">
              <input
                tabindex="0"
                type="text"
                name="name"
                bind:value="{$organization.name}"
                on:focus="{() => setInfoMessage(organizationNameMessage)}"
                on:blur="{() => setInfoMessage(defaultInfoMessage)}"
              />
            </div>
          </div>

          <div class="section-content">
            <h5>{$_('Create.Token_Name')}</h5>
            <div class="control is-expanded">
              <input
                type="text"
                name="name"
                bind:value="{$organization.token.name}"
                on:focus="{() => setInfoMessage(tokenNameMessage)}"
                on:blur="{() => setInfoMessage(defaultInfoMessage)}"
              />
            </div>
          </div>
        </div>

        <div class="column is-one-quarter-desktop is-mobile">
          <div class="section-content">
            <h5>{$_('Create.Management_Subdomain')}</h5>
            <div class="control is-expanded">
              <input
                type="text"
                name="name"
                bind:value="{$organization.subdomain}"
                placeholder=""
                on:focus="{() => setInfoMessage(subdomainMessage)}"
                on:blur="{() => setInfoMessage(defaultInfoMessage)}"
              />
            </div>
          </div>

          <div class="section-content">
            <h5>{$_('Create.Token_Symbol')}</h5>
            <div class="control is-expanded">
              <input
                type="text"
                name="name"
                bind:value="{$organization.token.symbol}"
                on:focus="{() => setInfoMessage(tokenSymbolMessage)}"
                on:blur="{() => setInfoMessage(defaultInfoMessage)}"
              />
            </div>
          </div>
        </div>

        <div class="column is-mobile is-hidden-mobile">
          {#if defaultMessage}
            <p class="blurb" class:hidden="{!defaultMessage}">
              {@html infoMessage}
            </p>
          {:else}
            <p class="blurb" class:hidden="{defaultMessage}">
              {@html infoMessage}
            </p>
          {/if}

          <img
            class="blurb-illustration is-hidden-mobile"
            src="{rocket}"
            alt="create organization"
          />
        </div>
      </div>

      <div class="columns">
        <div class="column is-6-desktop is-6-tablet is-mobile">
          <div class="summoners mt-6">
            <div class="header">
              <h5>
                {$_('Create.Summoners')}{`(${$organization.summoners.length})`}
              </h5>
              <button
                class="button action-button"
                on:click="{() => addSummoner()}"
              >
                <i class="fas fa-plus"></i>
              </button>
            </div>

            <div id="summoner">
              {#each $organization.summoners as summoner}
                <div class="control is-expanded has-icons-right">
                  <input
                    class="input"
                    type="text"
                    bind:value="{summoner}"
                    on:change="{refreshSummoners}"
                    placeholder="0x31a5t1cd40...."
                    on:focus="{() => setInfoMessage(summonerMessage)}"
                    on:blur="{() => setInfoMessage(defaultInfoMessage)}"
                  />
                  <span
                    class="icon is-right is-clickable"
                    on:click="{() => removeSummoner(summoner)}"
                  >
                    <i class="fas fa-minus"></i>
                  </span>
                </div>
              {/each}
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>

<Footer params="{params}" />
