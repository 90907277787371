<script>
import BigNumber from 'bignumber.js';
import { _ } from 'svelte-i18n';
import { capitalDelta, t } from '@elastic-dao/sdk';

import { amountFormatter } from '../../../utils.js';
import { balances, key } from '../../../stores/balances.js';
import { changeMembershipTab } from '../../../routes/push.js';
import { liveOrganizations } from '../../../stores/organizations.js';
import { MembershipTabs } from '../../../routes/constants.js';
import { oracle } from '../../../stores/oracle.js';
import AddressBar from '../../shared/AddressBar.svelte';
import Card from '../../shared/Card.svelte';

import { ethers } from 'ethers';

export let params = {};

const { account, keys } = liveOrganizations;

$: keySet = $keys[params.id];
$: dao = liveOrganizations[keySet.daoStoreKey];
$: token = liveOrganizations[keySet.tokenStoreKey];

const daoBalances = (balances) =>
  balances.filter(({ address }) =>
    [ethers.constants.AddressZero, $token.uuid]
      .map((address) => address.toLowerCase())
      .includes(address.toLowerCase()),
  );

const etherscanHref = (address) => {
  if (address === ethers.constants.AddressZero) {
    return `https://etherscan.io/address/${$account.address}`;
  }
  return `https://etherscan.io/token/${address}?a=${$account.address}`;
};

$: daoETHBalance = $balances[key($dao.uuid)] || BigNumber(0);
$: dollarValue = daoETHBalance.multipliedBy($oracle.eth);
$: tValue = $token.lambda ? t($token.lambda, $token.m, $token.k) : BigNumber(0);
$: delta = capitalDelta(daoETHBalance, tValue);
$: tDollarValue = delta.multipliedBy($oracle.eth);
</script>

`
<div class="tokens fade-in">
  <section class="section">
    <div class="section-header">
      <h1>{$_('Tokens.Tokens')}</h1>

      <button
        on:click="{() => changeMembershipTab(params.id, MembershipTabs.Join)}"
        class="button action-button"
      >
        <i class="fas fa-coins mr-3"></i>{$_('Tokens.mint_tokens')}
      </button>
    </div>

    <div class="columns is-multiline">
      <div class="column is-5 is-mobile">
        <Card
          cardTitle="{`${$token.name} (${$token.symbol})`}"
          identicon="{false}"
          noZoom="{true}"
        >
          <a href="{`https://etherscan.com/address/${$token.uuid}`}">
            <AddressBar address="{$token.uuid}" />
          </a>
        </Card>
      </div>

      <div class="column is-7 is-mobile">
        <Card cardTitle="" identicon="{false}" noZoom="{true}">
          <div class="card-data">
            <div class="mr-5">
              <h3>{$_('Tokens.Holders')}</h3>
              <p>
                {amountFormatter({
                  amount: $token.numberOfTokenHolders,
                  decimalPlaces: 0,
                  decimalShift: 18,
                })}
              </p>
            </div>

            <div class="mr-5">
              <h3>ETH/{$token.symbol}</h3>
              <p>{amountFormatter({ amount: delta })} ETH</p>
            </div>

            <div class="mr-5">
              <h3>USD/{$token.symbol}</h3>
              <p>
                {amountFormatter({ amount: tDollarValue, decimalPlaces: 2 })}
                USD
              </p>
            </div>

            <div>
              <h3>{$_('Tokens.Total_Supply')}</h3>
              <p>{amountFormatter({ amount: tValue })} {$token.symbol}</p>
            </div>
          </div>
        </Card>
      </div>
    </div>
  </section>
</div>

<div class="finance fade-in">
  <section class="section">
    <div class="section-header">
      <h1>{$_('Finance.Balances')}</h1>
    </div>

    <table class="table">
      <thead>
        <tr>
          <th><abbr title="Token">{$_('Finance.Token')}</abbr></th>
          <th><abbr title="Balance">{$_('Finance.Balance')}</abbr></th>
          <th><abbr title="Value">{$_('Finance.Value')}</abbr></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Ether</td>
          <td>{amountFormatter({ amount: daoETHBalance })} ETH</td>
          <td>
            {amountFormatter({ amount: dollarValue, decimalPlaces: 2 })}
            USD
          </td>
        </tr>
      </tbody>
    </table>
  </section>

  {#if $account.balances.length > 0}
    <section class="section">
      <div class="section-header">
        <h1>{$_('Finance.Your_Balances')}</h1>
      </div>

      <table class="table">
        <thead>
          <tr>
            <th><abbr title="Token">{$_('Finance.Token')}</abbr></th>
            <th><abbr title="Balance">{$_('Finance.Balance')}</abbr></th>
            <th colspan="2">
              <abbr title="Value">{$_('Finance.Value')}</abbr>
            </th>
          </tr>
        </thead>
        <tbody>
          {#each daoBalances($account.balances) as balance}
            <tr>
              <td>
                <a
                  href="{etherscanHref(balance.address)}"
                  alt="{balance.name}"
                  target="_blank"
                >{balance.name}</a>
              </td>
              <td>
                {amountFormatter({ amount: balance.amount })}
                {balance.symbol}
              </td>
              <td>
                {amountFormatter({
                  amount: balance.valueUSD,
                  decimalPlaces: 2,
                })}
                USD
              </td>
              <td>{amountFormatter({ amount: balance.valueETH })} ETH</td>
            </tr>
          {/each}
        </tbody>
      </table>
    </section>
  {/if}

  <!-- NOTE: Waiting on archive node integration
  <section class="section">
    <div class="section-header">
      <h1>{$_('Finance.Transfers')}</h1>

      <button class="button action-button">
        <i class="fas fa-file-export mr-3"></i>{$_('Finance.export')}
      </button>
    </div>

    <table class="table">
      <thead>
        <tr>
          <th><abbr title="Date">{$_('Finance.Date')}</abbr></th>
          <th><abbr title="To/From">{$_('Finance.To/From')}</abbr></th>
          <th><abbr title="Amount">{$_('Finance.Amount')}</abbr></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>2020-10-7</td>
          <td>0x15d...5d4fbv</td>
          <td>+0.0001 ETH</td>
        </tr>
      </tbody>
    </table>
  </section>
  -->
</div>
