<script>
const showTokenInformation = (uuid) => {};
import { shortenAddress } from '@pie-dao/utils';
import { truncate } from '../../utils.js';
import * as jdenticon from 'jdenticon';

export let address;
export let light = false;
export let contentLight = false;
export let shorten = true;
export let ethereum = true;
</script>

<div class="address-bar">
  <div class="{light ? 'address-bar-icon light' : 'address-bar-icon'}">
    {@html jdenticon.toSvg(address, 35)}
  </div>
  <div
    class="{contentLight ? 'address-bar-content light' : 'address-bar-content'}"
  >
    {shorten && ethereum ? shortenAddress(address) : truncate(address, {
          length: 25,
        })}
  </div>
</div>
