import BigNumber from 'bignumber.js';
import {
  isNumber,
  validateIsBigNumber,
  validateIsNumber,
} from '@pie-dao/utils';

export const amountFormatter = ({
  amount,
  approximatePrefix = '~',
  decimalPlaces = 3,
  decimalShift = 0,
  fromEthers = false,
  lessThanPrefix = '< ',
  maxDigits,
  rounding = BigNumber.ROUND_DOWN,
}) => {
  if (!amount) {
    return '';
  }

  let decimals = decimalPlaces;
  const prefix = 'elasticdao.org/utils - amountFormatter';
  let value = BigNumber(amount.toString());

  if (fromEthers) {
    value = value.dividedBy(10 ** 18);
  }

  if (decimalShift) {
    value = value.multipliedBy(10 ** decimalShift);
  }

  validateIsBigNumber(value, { prefix });

  if (isNumber(maxDigits)) {
    let left = 0;
    while (BigNumber(10 ** left).isLessThan(value)) {
      left += 1;
    }
    const maxDecimals = maxDigits - left;
    if (maxDecimals < 0) {
      decimals = 0;
    } else if (maxDecimals < decimals) {
      decimals = maxDecimals;
    }
  }

  if (value.isZero()) {
    return value.toFixed(decimals);
  }

  const smallest = BigNumber(1)
    .dividedBy(10 ** decimals)
    .toString();

  if (value.isLessThan(smallest)) {
    return `${lessThanPrefix}${smallest}`;
  }

  const base = value.toFormat(decimals, rounding);

  if (value.isGreaterThan(base)) {
    return `${approximatePrefix}${base}`;
  }

  return base;
};

export const buildError = ({
  message,
  prefix = 'elasticdao.org/utils - validations',
}) => `${prefix}: ${message}`;

export const compareAddresses = (a, b) =>
  a && b && a.toLowerCase() === b.toLowerCase();

export const minimumValue = (a, b) => {
  const x = sanitizeAmount(a);
  const y = sanitizeAmount(b);

  if (x.isLessThan(y)) {
    return a;
  }

  return b;
};

export const dig = (obj, ...keys) => {
  if (keys.length === 0 || !obj) {
    return obj;
  }

  const parts = [...keys];
  const nextObj = obj[parts.shift()];

  return dig(nextObj, ...parts);
};

/** Dispatch event on click outside of node */
export const onClickOutside = (node) => {
  const handleClick = (event) => {
    if (node && !node.contains(event.target) && !event.defaultPrevented) {
      node.dispatchEvent(new CustomEvent('click_outside', node));
    }
  };

  document.addEventListener('click', handleClick, true);

  return {
    destroy() {
      document.removeEventListener('click', handleClick, true);
    },
  };
};

export const sanitizeAmount = (value) => {
  const amount = BigNumber(value);
  if (amount.isNaN() || amount.isLessThan(0)) {
    return BigNumber(0);
  }
  return amount;
};

export const sanitizeAndFormatAmount = (value) => {
  const amount = sanitizeAmount(value);
  return amountFormatter({ amount });
};

export const swapKV = (obj) => {
  const ret = {};
  const keys = Object.keys(obj);
  for (let i = 0; i < keys.length; i += 1) {
    ret[obj[keys[i]]] = keys[i];
  }
  return ret;
};

export const truncate = (str, opts = {}) => {
  const ending = opts.ending || '...';
  const length = opts.length || 40;

  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  }

  return str;
};

export const upTo = (n) => {
  validateIsNumber(n);
  const arr = [];
  for (let i = 0; i < n; i += 1) {
    arr.push(n - 1);
  }
  return arr;
};

export const validate = (result, options) => {
  const { level = 'error', message, prefix, throwError = true } = options;

  if (result) {
    return true;
  }

  const error = buildError({ message, prefix });

  if (throwError) {
    throw new TypeError(error);
  }

  console[level](error);
  return false;
};

export const utils = {
  amountFormatter,
  buildError,
  compareAddresses,
  minimumValue,
  onClickOutside,
  sanitizeAmount,
  sanitizeAndFormatAmount,
  swapKV,
  truncate,
  upTo,
  validate,
};
