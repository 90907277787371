import { wrap } from 'svelte-spa-router/wrap';

// Pages
import Client from '../pages/Client.svelte';
import Create from '../pages/Create.svelte';
import Landing from '../pages/Landing.svelte';
import Membership from '../pages/Membership.svelte';
import NotFound from '../pages/NotFound.svelte';
import Organization from '../pages/Organization.svelte';
import OrganizationSettings from '../pages/OrganizationSettings.svelte';

import {
  disableLoading,
  includePath,
  organizationClearfix,
  preloadOrganizationData,
  preloadOrganizationModule,
  // preloadOrganizationModules,
  preloadOrganizationSettings,
  preloadQuery,
} from './conditions';

import { path } from './push';
import { Paths } from './constants';

export {
  DashboardTabs,
  DefaultTab,
  MembershipTabs,
  ModuleMappings,
  OrganizationSettingsTabs,
  Paths,
  SubTypes,
  VoteFilters,
} from './constants';

export {
  changeDashboardTab,
  changeMembershipTab,
  changeOrganizationSettingsTab,
  changeVoteFilter,
  go,
  path,
} from './push';

export const routes = {
  '/': wrap({
    component: Landing,
    conditions: [disableLoading],
  }),
};

routes[path(Paths.Client)] = wrap({
  component: Client,
  conditions: [disableLoading],
});

routes[path(Paths.Create)] = wrap({
  component: Create,
  conditions: [organizationClearfix, disableLoading],
});

routes[path(Paths.Dashboard, ':id', ':tab?')] = wrap({
  component: Organization,
  conditions: [preloadOrganizationData],
});

routes[path(Paths.Membership, ':id', ':tab?')] = wrap({
  component: Membership,
  conditions: [preloadOrganizationData],
});

routes[path(Paths.Modules, ':id', ':name?')] = wrap({
  component: Organization,
  conditions: [
    includePath,
    preloadOrganizationData,
    // preloadOrganizationModules,
    preloadOrganizationModule,
    preloadQuery,
  ],
});

routes[path(Paths.OrganizationSettings, ':id', ':tab?')] = wrap({
  component: OrganizationSettings,
  conditions: [preloadOrganizationData, preloadOrganizationSettings],
});

routes['*'] = wrap({ component: NotFound, conditions: [disableLoading] });
