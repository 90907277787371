import { isString } from '@pie-dao/utils/src/utils/typeChecks';
import { ethers } from 'ethers';
import { get, writable } from 'svelte/store';

import sdk from '../config/sdk';
import { ModuleMappings } from '../routes/constants';
import { organization } from './organization';

export const modules = {
  // SnapshotVoteModule
  '0x0000000000000000000000000000000000000001': {
    name: 'SnapshotVoteModule',
  },
  status: writable({
    loading: true,
    whitelist: Object.values(ModuleMappings),
    version: 1,
  }),
};

export const loadModule = async (name, idOnly = true) => {
  if (modules[name]) {
    if (isString(modules[name])) {
      return loadModule(modules[name], idOnly);
    }
    if (idOnly) {
      return modules[name].id;
    }
    return modules[name];
  }

  if (name === 'SnapshotVoteModule') {
    return {};
  }

  const { dao } = get(organization);
  const module = await sdk.models.ElasticModule.deserializeByName(name, dao);

  if (module.uuid === ethers.constants.AddressZero) {
    throw new Error(
      `elasticdao.org - store/modules.js: Module ${name} not found.`,
    );
  }

  modules[module.id] = module;
  modules[name] = module.id;

  const status = get(modules.status);
  modules.status.set({
    ...status,
    version: status.version + 1,
  });

  if (idOnly) {
    return module.id;
  }
  return module;
};

export const loadModules = async () => {
  const { whitelist } = get(modules.status);

  for (let i = 0; i < whitelist.length; i += 1) {
    try {
      if (whitelist[i] === 'SnapshotVoteModule') {
        return;
      }

      // eslint-disable-next-line no-await-in-loop
      await loadModule(whitelist[i]);
    } catch (e) {
      console.warn(`no ${whitelist[i]} module found for organization `);
    }
  }
};

export const resetModules = async () => {
  const keys = Object.keys(modules);

  for (let i = 0; i < keys.length; i += 1) {
    if (keys[i] !== 'status') {
      delete modules[keys[i]];
    }
  }
};
