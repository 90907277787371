<script>
import { _ } from 'svelte-i18n';

export let loading = false;
</script>

{#if loading}
  <div class="loading">
    <div class="notification mt-3">
      <i class="fas fa-spinner fa-spin"></i>
      {$_('Loading.Please_be_patient_we_are_processing_your_information')}
    </div>
  </div>
{/if}
