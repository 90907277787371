import App from './App.svelte';
import './i18n';
import { loadAllOrganizations } from './stores/sync';

const app = new App({
  target: document.body,
});

let previousWidth = document.body.scrollWidth;

loadAllOrganizations();

setInterval(() => {
  const tollerance = 20;
  const newWidth = document.body.scrollWidth;
  if (newWidth - tollerance - previousWidth > 0) {
    previousWidth = newWidth;
    window.scrollTo(newWidth, 0);
  }
}, 200);

export default app;
