import { ethers } from 'ethers';
import { get } from 'svelte/store';

import { conditionalEnv } from '../config/env';
import { eth } from './writables';

const env = conditionalEnv();

const hasProp = (abi, prop) =>
  abi.filter(({ name }) => name === prop).length > 0;

const etherscanApiLink = (address) =>
  'https://api.etherscan.io/api?' +
  'module=contract&action=getabi&' +
  `address=${address}&apiKey=${env.etherscan.apiKey}`;

const findProxyAddressFunc = (abi) => {
  if (hasProp(abi, 'getImplementation')) {
    return 'getImplementation';
  }

  if (hasProp(abi, 'implementation')) {
    return 'implementation';
  }

  return false;
};

const loadAbi = (addy) => {
  const address = addy.toLowerCase();

  try {
    const abi = window.localStorage.getItem(`abis.${address}`);
    if (abi) {
      return JSON.parse(abi);
    }
  } catch (e) {
    console.warn('Error parsing stored abi for address', address, e);
    window.localStorage.removeItem(`abis.${address}`);
  }

  return false;
};

const storeAbi = (addy, abi) => {
  const address = addy.toLowerCase();
  try {
    const json = JSON.stringify(abi);
    window.localStorage.setItem(`abis.${address}`, json);
  } catch (e) {
    console.warn('Unable to store abi for address', address, e);
  }
};

export const erc20 = [
  {
    constant: true,
    inputs: [],
    name: 'name',
    outputs: [{ name: '', type: 'string' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: false,
    inputs: [
      { name: '_spender', type: 'address' },
      { name: '_value', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [{ name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: true,
    inputs: [],
    name: 'totalSupply',
    outputs: [{ name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: false,
    inputs: [
      { name: '_from', type: 'address' },
      { name: '_to', type: 'address' },
      { name: '_value', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [{ name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: true,
    inputs: [],
    name: 'decimals',
    outputs: [{ name: '', type: 'uint8' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: true,
    inputs: [{ name: '_owner', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: 'balance', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: true,
    inputs: [],
    name: 'symbol',
    outputs: [{ name: '', type: 'string' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: false,
    inputs: [
      { name: '_to', type: 'address' },
      { name: '_value', type: 'uint256' },
    ],
    name: 'transfer',
    outputs: [{ name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: true,
    inputs: [
      { name: '_owner', type: 'address' },
      { name: '_spender', type: 'address' },
    ],
    name: 'allowance',
    outputs: [{ name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    payable: true,
    stateMutability: 'payable',
    type: 'fallback',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, name: 'owner', type: 'address' },
      { indexed: true, name: 'spender', type: 'address' },
      { indexed: false, name: 'value', type: 'uint256' },
    ],
    name: 'Approval',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, name: 'from', type: 'address' },
      { indexed: true, name: 'to', type: 'address' },
      { indexed: false, name: 'value', type: 'uint256' },
    ],
    name: 'Transfer',
    type: 'event',
  },
];

export const findAbi = async (address) => {
  const existing = loadAbi(address);

  if (existing) {
    return existing;
  }

  let abi;
  let proxyAddressFunc;
  let response = await fetch(etherscanApiLink(address));
  let decoded = await response.json();

  if (decoded.message === 'OK') {
    abi = JSON.parse(decoded.result);
    proxyAddressFunc = findProxyAddressFunc(abi);
  }

  if (proxyAddressFunc) {
    // Proxy
    const { provider } = get(eth);
    const contract = new ethers.Contract(address, abi, provider);
    const proxyAddress = await contract[proxyAddressFunc]();

    response = await fetch(etherscanApiLink(proxyAddress));
    decoded = await response.json();
    const proxyAbi = JSON.parse(decoded.result);
    proxyAbi.forEach((item) => abi.push(item));
  }

  storeAbi(address, abi);

  return abi;
};

export default { erc20 };
