<script>
import Dashboard from './Dashboard.svelte';
import NewVote from './NewVote.svelte';

export let sdk;
export let params = {};
</script>

{#if params.action === 'new'}
  <NewVote params="{params}" sdk="{sdk}" />
{:else}
  <Dashboard params="{params}" sdk="{sdk}" />
{/if}
