import { SDK } from '@elastic-dao/sdk';

import { ethers } from 'ethers';
import { defaultProvider as provider } from '../subeth';
import { conditionalEnv } from './env';

const env = conditionalEnv();

const contract = ({ abi, address }) =>
  new ethers.Contract(address, abi, provider);

export default new SDK({ contract, env, provider, live: true });
