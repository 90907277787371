<script>
import * as jdenticon from 'jdenticon';
import { _ } from 'svelte-i18n';
import { t } from '@elastic-dao/sdk';

import { amountFormatter, truncate } from '../../../utils.js';
import { balances, key } from '../../../stores/balances.js';
import { changeMembershipTab, Paths, go } from '../../../routes/index.js';
import { MembershipTabs } from '../../../routes/constants.js';
import { liveOrganizations } from '../../../stores/organizations.js';

export let params = {};

const vote = 'images/votes.svg';
const exit = 'images/exit.svg';
const join = 'images/join.svg';

const { keys } = liveOrganizations;

$: keySet = $keys[params.id];
$: dao = liveOrganizations[keySet.daoStoreKey];
$: token = liveOrganizations[keySet.tokenStoreKey];
$: organizationBalance = $balances[key(params.id)];

$: totalSupply = t($token.lambda, $token.m, $token.k);
</script>

<div class="home fade-in">
  <section class="section">
    <div class="columns">
      <div class="column is-mobile">
        <div class="dao-details">
          <div class="dao-icon">
            {@html jdenticon.toSvg($dao.name, 40)}
          </div>
          <div class="dao-info">
            <h5 class="mb-1">
              <a
                href="{`https://etherscan.com/address/${$dao.id}`}"
                target="_blank"
              >
                {$dao.name && truncate($dao.name)}
              </a>
            </h5>
          </div>
        </div>
      </div>
      <div class="column is-mobile dao-data">
        <div class="level">
          <div class="level-item has-text-centered">
            <div>
              {#if $dao.summoned}
                <p class="heading">{$_('Home.Members')}</p>
                <p class="title">
                  {amountFormatter({
                    amount: $token.numberOfTokenHolders,
                    decimalPlaces: 0,
                    decimalShift: 18,
                  })}
                </p>
              {:else}
                <p class="heading">{$_('Home.Summoners')}</p>
                <p class="title">
                  {amountFormatter({
                    amount: $dao.numberOfSummoners,
                    decimalPlaces: 0,
                    decimalShift: 18,
                  })}
                </p>
              {/if}
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">{$_('Home.Total_Supply')}</p>
              <p class="title">
                {amountFormatter({ amount: totalSupply })}
                {$token.symbol}
              </p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">{$_('Home.Asset_Value')}</p>
              <p class="title">
                {amountFormatter({ amount: organizationBalance })}
                <i class="fab fa-ethereum"></i>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="summoner mt-5">
        <p class="mb-1">{$_('Home.Summoners')}</p>

        {#each $dao.summoners as summoner}
          <div class="dao-icon">
            <a
              href="{`https://etherscan.com/address/${summoner}`}"
              target="_blank"
              class="is-clickable has-tooltip-right"
              data-tooltip="{`${summoner}`}"
            >
              {@html jdenticon.toSvg(summoner, 30)}
            </a>
          </div>
        {/each}
      </div>
    </div>
  </section>

  <section class="section todo">
    <div class="section-header">
      <h1>
        {#if $dao.summoned}
          {$_('Home.What_would_you_like_to_do')}
        {:else}{$_('Home.Next_Steps')}{/if}
      </h1>
    </div>

    <div class="columns">
      <div
        class="column is-mobile is-clickable"
        on:click="{() => changeMembershipTab(params.id, MembershipTabs.Join)}"
      >
        <div class="panel has-text-centered">
          <img src="{join}" alt="tokens" />
          {#if $dao.summoned}
            <p>{$_('Home.Join_Organization')}</p>
          {:else}
            <p>{$_('Home.Summon_DAO')}</p>
          {/if}
        </div>
      </div>

      <div
        class="column is-mobile is-clickable"
        on:click="{() => changeMembershipTab(params.id, MembershipTabs.Exit)}"
      >
        <div class="panel has-text-centered">
          <img src="{exit}" alt="tokens" />
          <p>Exit</p>
        </div>
      </div>

      <div
        class="column is-mobile is-clickable"
        on:click="{() => go(Paths.Modules, params.id, 'snapshot-voting')}"
      >
        <div class="panel has-text-centered">
          <img src="{vote}" alt="vote" />
          <p>Vote</p>
        </div>
      </div>
    </div>
  </section>
</div>
