<script>
import {
  changeDashboardTab,
  DashboardTabs,
  SubTypes,
} from '../../../routes/index.js';

import { _ } from 'svelte-i18n';

export let params = {};
</script>

<div class="tabs is-max-desktop">
  <ul>
    <li
      class="{(params.tab === DashboardTabs.Home || params.tab === null) && 'is-active'}"
    >
      <button
        on:click="{() => changeDashboardTab(params.id, DashboardTabs.Home)}"
      >{$_('Nav.Home')}</button>
    </li>
    <li class="{params.subType === SubTypes.Module && 'is-active'}">
      <button
        on:click="{() => changeDashboardTab(params.id, DashboardTabs.Modules)}"
      >{$_('Nav.Modules')}</button>
    </li>
    <li class="{params.tab === DashboardTabs.Finances && 'is-active'}">
      <button
        on:click="{() => changeDashboardTab(params.id, DashboardTabs.Finances)}"
      >{$_('Nav.Finances')}</button>
    </li>
  </ul>
</div>
