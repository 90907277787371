<script>
import { DashboardTabs, SubTypes } from '../../../routes/index.js';
import Finance from './Finance.svelte';
import Home from './Home.svelte';
import ModulesContainer from '../modules/Container.svelte';
import Join from './Join.svelte';
import Exit from './Exit.svelte';

export let params = {};
</script>

<div class="tabs-content">
  {#if params.subType === SubTypes.Core}
    {#if !params.tab || params.tab === DashboardTabs.Home}
      <Home params="{params}" />
    {/if}
    {#if params.tab === DashboardTabs.Finances}
      <Finance params="{params}" />
    {/if}
    {#if params.tab === DashboardTabs.Join}
      <Join params="{params}" />
    {/if}
    {#if params.tab === DashboardTabs.Exit}
      <Exit params="{params}" />
    {/if}
  {/if}
  {#if params.subType === SubTypes.Module}
    <ModulesContainer params="{params}" />
  {/if}
</div>
