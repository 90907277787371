import { get } from 'svelte/store';
import { isAddress } from '@pie-dao/utils';

import { liveOrganizations, organizations } from './organizations';
import { subscribeToEtherBalance } from './balances';
import sdk from '../config/sdk';

export const addAccount = ({ address }) => {
  if (isAddress(address)) {
    subscribeToEtherBalance(address);
    liveOrganizations.changeAccount(address);
  }
};

export const loadOrganization = async (id) => {
  await liveOrganizations.add(id);
  trackETHBalance(id);

  const status = get(organizations.status);
  organizations.status.set({
    ...status,
    version: status.version + 1,
  });
};

export const loadAllOrganizations = async () => {
  organizations.status.set({
    ...get(organizations.status),
    initialized: false,
  });

  const deployedDAOAddresses = await sdk.elasticDAOFactory.deployedDAOAddresses();

  await Promise.all(
    deployedDAOAddresses.map((address) => loadOrganization(address)),
  );

  organizations.status.set({ ...get(organizations.status), initialized: true });
};

export const selectOrganization = async (id) => {
  await loadOrganization(id);
};

export const trackETHBalance = (address) => {
  subscribeToEtherBalance(address, true);
};

export const updateSDKSigner = ({ address, signer }) => {
  if (signer) {
    // eslint-disable-next-line no-param-reassign
    signer.address = signer.address || address;
    trackETHBalance(signer.address);
    sdk.changeSigner(signer);
  }
};

export default {
  updateSDKSigner,
};
