const proposalMessage = {
  version: '0.1.3',
  timestamp: '',
  space: 'edao.eth',
  type: 'proposal',
  payload: {
    name: '',
    body: '',
    choices: ['Yes', 'No', 'Abstain'],
    start: null,
    end: null,
    snapshot: 'latest',
    metadata: {
      strategies: [
        {
          name: 'contract-call',
          params: {
            start: 8928158,
            address: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
            decimals: 18,
            symbol: 'DAI',
            args: ['%{address}', '0x0a68fA3eFe271Ce098553480BF5969A4fA4b663B'],
            methodABI: {
              constant: true,
              inputs: [
                {
                  internalType: 'address',
                  name: '',
                  type: 'address',
                },
                {
                  internalType: 'address',
                  name: '',
                  type: 'address',
                },
              ],
              name: 'allowance',
              outputs: [
                {
                  internalType: 'uint256',
                  name: '',
                  type: 'uint256',
                },
              ],
              payable: false,
              stateMutability: 'view',
              type: 'function',
            },
          },
        },
      ],
    },
  },
};

const voteMessage = {
  payload: {
    choice: null,
    metadata: {},
    proposal: '', // proposal.id ipfsHash
  },
  space: 'edao.eth',
  timestamp: null,
  type: 'vote',
  version: '0.1.3',
};

export { proposalMessage, voteMessage };
