import { ethers } from 'ethers';
import { get } from 'svelte/store';
import { isAddress, validateIsAddress } from '@pie-dao/utils';
import BigNumber from 'bignumber.js';

import {
  balanceKey,
  balances as subethBalances,
  bumpLifecycle,
  contract,
  erc20,
  trackBalance,
} from '../subeth';

const balanceSubscriptions = new Set();

export const balances = subethBalances;

export const key = (
  address,
  tokenAddress = ethers.constants.AddressZero,
  ...suffixes
) => {
  if (!isAddress(address) || !isAddress(tokenAddress)) {
    return '';
  }

  const parts = [balanceKey(tokenAddress, address), ...suffixes];

  return parts.join('|');
};

export const subscribeToBalance = async (
  tokenAddress,
  address,
  shouldBump = true,
) => {
  let token = tokenAddress;

  if (!token) {
    token = ethers.constants.AddressZero;
  }

  if (!token || token === '') return;
  validateIsAddress(token);
  validateIsAddress(address);

  const k = key(address, token);

  if (balanceSubscriptions.has(k)) {
    return;
  }

  balanceSubscriptions.add(k);

  const observable = await trackBalance(address, tokenAddress);
  let decimals = 18;

  if (token !== ethers.constants.AddressZero) {
    const tokenContract = await contract({ address: token, abi: erc20 });
    decimals = await tokenContract.decimals();
  }

  observable.subscribe({
    next: async (updatedBalance) => {
      const updates = {};
      updates[k] = BigNumber(updatedBalance).dividedBy(10 ** decimals);
      subethBalances.set({ ...get(subethBalances), ...updates });
    },
  });

  if (shouldBump) {
    bumpLifecycle();
  }
};

export const subscribeToEtherBalance = async (address, shouldBump = true) =>
  subscribeToBalance(ethers.constants.AddressZero, address, shouldBump);
