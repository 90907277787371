<script>
import Footer from '../components/Footer.svelte';
import Header from '../components/Header.svelte';
import { _ } from 'svelte-i18n';

const FourOhFour = 'images/404.svg';
export let params = {};
</script>

<Header params="{params}" />

<div class="not-found">
  <div class="container is-max-desktop mt-6">
    <section class="section">
      <h1 class="title has-text-centered">Error 404</h1>
      <h2 class="sub-title has-text-centered">
        {$_('NotFound.This_page_could_not_be_found')}
      </h2>

      <div class="container">
        <div class="technical-difficulties">
          <img src="{FourOhFour}" alt="404 Error" />
        </div>
      </div>
    </section>
  </div>
</div>

<Footer params="{params}" />
