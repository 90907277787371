import { get, writable } from 'svelte/store';

export const oracle = writable({ eth: '0' });

const getPricesFromCoingecko = async () => {
  const response = await fetch(
    'https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd',
  );
  const {
    ethereum: { usd: price },
  } = await response.json();
  oracle.set({ ...get(oracle), eth: price });
};

setInterval(getPricesFromCoingecko, 5000);

export default { oracle };
