import { get } from 'svelte/store';
import { isPOJO } from '@pie-dao/utils';
import qs from 'qs';

import { determineModuleComponent, loadModuleData } from './modules';
// import { loadInformationalVoteModule } from '../stores/informationalVoteModule';
import { liveOrganizations, loading } from '../stores/organizations';
import { loadModule, loadModules, resetModules } from '../stores/modules';
// import { loadTransactionalVoteModule } from '../stores/transactionalVoteModule';
import { ModuleMappings, Paths, SubTypes } from './constants';
import { organization, resetOrganization } from '../stores/organization';
import { selectOrganization } from '../stores/sync';
import sdk from '../config/sdk';

export const disableLoading = async (detail) => {
  loading(false);
  return detail;
};

export const includePath = async ({ location, params }) => {
  const parts = location.split('?')[0].split('/');
  parts.shift();
  return { params: { ...params, path: parts } };
};

export const organizationClearfix = () => {
  resetOrganization();
  resetModules();
  return true;
};

export const preloadQuery = async (detail) => {
  const queryParams = qs.parse(detail.querystring);
  if (isPOJO(queryParams)) {
    return { params: { ...queryParams, ...detail.params } };
  }
  return true;
};

export const preloadOrganizationData = async (detail) => {
  const { params } = detail;
  const org = get(organization);

  const additions = {
    logoPath: [Paths.Dashboard, params.id],
    subType: SubTypes.Core,
  };

  if (org.id && org.id.toLowerCase() === params.id) {
    return { params: { ...params, ...additions } };
  }

  loading(true);
  await Promise.all([
    selectOrganization(params.id),
    liveOrganizations.add(params.id),
  ]);
  loading(false);

  return { params: { ...params, ...additions } };
};

export const preloadOrganizationModule = async (detail) => {
  const { params } = detail;

  if (!params.name) {
    return { params: { ...params, subType: SubTypes.Module, fail: 58 } };
  }

  try {
    const moduleName = ModuleMappings[params.name] || params.name;
    const module = await loadModule(moduleName, false);

    if (moduleName === 'SnapshotVoteModule') {
      const moduleComponent = determineModuleComponent({ moduleName });

      return {
        params: {
          ...params,
          moduleId: 'snapshot-voting',
          moduleAddress: '0x0000000000000000000000000000000000000001',
          moduleName,
          moduleComponent,
          subType: SubTypes.Module,
        },
      };
    }

    if (module && module.id) {
      const moduleAddress = module.uuid;
      const moduleId = module.id;
      const updatedParams = {
        ...params,
        moduleAddress,
        moduleId,
        moduleName,
        subType: SubTypes.Module,
      };
      const moduleComponent = determineModuleComponent(updatedParams);
      const additionalParams = await loadModuleData(updatedParams, sdk);
      return {
        params: {
          ...updatedParams,
          moduleComponent,
          ...additionalParams,
          fail: 78,
        },
      };
    }

    return { params: { ...params, subType: SubTypes.Module, fail: 82 } };
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const preloadOrganizationModules = async () => {
  try {
    await loadModules();
  } catch (e) {
    console.error(e);
  }
  return true;
};

export const preloadOrganizationSettings = async ({ params }) => {
  // try {
  //   await loadInformationalVoteModule(params.id);
  // } catch (e) {
  //   console.error(e);
  // }

  // try {
  //   await loadTransactionalVoteModule(params.id);
  // } catch (e) {
  //   console.error(e);
  // }

  const updates = { params };
  return updates;
};
