<script>
import Card from './Card.svelte';
import { go } from '../../routes/index.js';

export let actionPath = '';
export let addresses = [];
export let nameGenerator = (address) => address;
export const params = {};
export let path = '/:address';
export let pathGenerator = (part, address) => {
  if (part === ':address') {
    return address;
  }
  return part;
};
export let subtitle = '';
export let title = 'Select an address';

const pathParts = (address) =>
  path
    .split('/')
    .filter((part) => part && part.length > 0)
    .map((part) => pathGenerator(part, address));

$: actionPathParts = Array.isArray(actionPath)
  ? actionPath
  : actionPath.split('/').filter((part) => part && part.length > 0);
</script>

<section class="section address-cards">
  <div class="section-header">
    <h1>{title}</h1>
    {#if actionPath.length > 0}
      <button
        class="button action-button"
        on:click="{() => go(...actionPathParts)}"
      >
        <i class="fas fa-plus-circle"></i>{subtitle}
      </button>
    {/if}
  </div>

  <div class="container">
    <div class="columns is-multiline">
      {#each addresses as address}
        <div class="column is-one-third-desktop is-one-third-tablet is-mobile">
          <div on:click="{() => go(...pathParts(address))}">
            <Card cardTitle="{nameGenerator(address)}" />
          </div>
        </div>
      {/each}
    </div>
  </div>
</section>
