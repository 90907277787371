<script>
import { onMount } from 'svelte';
import SimplePicker from 'simplepicker';
import { _ } from 'svelte-i18n';

import { proposalMessage } from '../../../../snapshot/message.js';
import { publish } from '../../../../snapshot';
import { go, Paths } from '../../../../routes/index.js';
import { eth } from '../../../../subeth/index.js';

const defaultMessage = `Vote length is determined by block number.
    The number of blocks will be estimated by the length of time in days.`;
const descriptionMessage = `A detailed description of what you are voting on`;

export let params;
export let sdk;

let infoMessage = defaultMessage;
let message = proposalMessage;
let publishing = false;
let tx;
let whichPicker;

// date pickers
const pickerElement = document.getElementById('picker');
let picker = new SimplePicker(pickerElement);
$: picker.on('submit', (date) =>
  whichPicker === 'start'
    ? (message.payload.start = Math.floor(new Date(date).getTime() / 1000))
    : (message.payload.end = Math.floor(new Date(date).getTime() / 1000)),
);
$: picker.on('close', () => (whichPicker = ''));

onMount(() => {
  tx = document.getElementById('tx');

  tx.setAttribute(
    'style',
    'height:' + tx.scrollHeight + 'px; overflow-y: hidden;',
  );
  tx.addEventListener('input', onInput, false);
});

const onInput = () => {
  tx.style.height = 'auto';
  tx.style.height = tx.scrollHeight + 'px';
};

const openPicker = (openPicker) => {
  whichPicker = openPicker;
  picker.open();
};

const setInfoMessage = (_message) => {
  infoMessage = _message;
};

const publishProposal = async () => {
  publishing = true;

  message.timestamp = Math.floor(new Date().getTime() / 1000).toString();

  const signedMessage = await $eth.signer.signMessage(JSON.stringify(message));

  await publish({
    address: $eth.address,
    msg: JSON.stringify(message),
    sig: signedMessage,
  });

  publishing = false;
  go(Paths.Modules, params.id, 'snapshot-voting');
};
</script>

<p
  class="is-clickable back-button mb-3"
  on:click="{() => go(Paths.Modules, params.id, 'snapshot-voting')}"
>
  <i class="fas fa-angle-left"></i>
  {$_('NewVote.Back_to_Votes')}
</p>

<form novalidate on:submit|preventDefault>
  <section class="section fade-in">
    <div class="section-header">
      <h1 class="mb-3">{$_('NewVote.New_Snapshot_Vote')}</h1>

      <div class="controls">
        <button
          class="button action-button mt-3"
          on:click="{() => publishProposal()}"
          disabled="{!$eth.address || !message.payload.start || !message.payload.end || !message.payload.name || !message.payload.body}"
        ><i
            class="{publishing ? 'fas fa-spinner fa-spin mr-3' : 'fas fa-poll mr-3'}"
          ></i>
          {$_('NewVote.Publish')}</button>
      </div>
    </div>

    <div class="columns mb-0 is-multiline">
      <div class="column is-one-quarter is-mobile">
        <div class="section-content">
          <button class="button ghost" on:click="{() => openPicker('start')}"><i
              class="fas fa-calendar"
            ></i>{message.payload.start ? new Date(message.payload.start * 1000).toDateString() : $_('Dashboard.Start_Date')}</button>
          <div id="picker"></div>
        </div>
      </div>

      <div class="column is-one-quarter is-mobile">
        <div class="section-content">
          <div class="control is-expanded">
            <button class="button ghost" on:click="{() => openPicker('end')}"><i
                class="fas fa-calendar"
              ></i>{message.payload.end ? new Date(message.payload.end * 1000).toDateString() : $_('Dashboard.End_Date')}</button>
          </div>
        </div>
      </div>

      <div class="column is-5 is-offset-1 is-hidden-mobile">
        <p class="blurb">
          {@html infoMessage}
        </p>
      </div>

      <div class="column is-one-quarter">
        <div class="section-content">
          <h5>{$_('NewVote.Snapshot_Block')}</h5>

          <div class="control is-expanded">
            <input
              type="number"
              step="1"
              min="0"
              bind:value="{message.payload.snapshot}"
              on:focus="{() => setInfoMessage(defaultMessage)}"
              on:blur="{() => setInfoMessage(defaultMessage)}"
              placeholder="Default: Latest"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="columns is-multiline mb-0">
      <div class="column is-4 is-mobile">
        <div class="section-content">
          <h5>{$_('NewVote.Title')}</h5>

          <div class="control mb-0 is-expanded">
            <input
              type="text"
              bind:value="{message.payload.name}"
              on:focus="{() => setInfoMessage(defaultMessage)}"
              on:blur="{() => setInfoMessage(defaultMessage)}"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="columns is-multiline mb-0">
      <div class="column is-half is-mobile">
        <div class="section-content mt-5">
          <h5>{$_('NewVote.Proposal')}</h5>

          <div class="control">
            <textarea
              id="tx"
              on:focus="{() => setInfoMessage(descriptionMessage)}"
              on:blur="{() => setInfoMessage(defaultMessage)}"
              bind:value="{message.payload.body}"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  </section>
</form>
