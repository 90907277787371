<script>
import { _ } from 'svelte-i18n';
import { get } from 'svelte/store';
import { utils } from '@elastic-dao/sdk';
import { liveOrganizations, organizations } from '../stores/organizations.js';
import { path, Paths } from '../routes/index.js';

// Components
import AddressCards from '../components/shared/AddressCards.svelte';
import Footer from '../components/Footer.svelte';
import Header from '../components/Header.svelte';
import Loading from '../components/Loading.svelte';

export let params = {};

const { toKey } = utils;
const { keys } = liveOrganizations;
const { status } = organizations;

$: organizationsAddresses = Object.keys($keys);

$: nameGenerator = (address) =>
  liveOrganizations[toKey(address, 'SDKObjects')].dao.name;

// when we're ready to re-enable create-org
// actionPath="{Paths.Create}"
</script>

<Loading loading="{$status.initialized === false}" />

<Header params="{params}" />

<div class="client fade-in">
  <div class="container is-max-desktop">
    <h1 class="title has-text-centered">
      {$_('Client.Browse_Elastic_Organizations')}
    </h1>

    <AddressCards
      addresses="{organizationsAddresses}"
      nameGenerator="{nameGenerator}"
      params="{params}"
      path="{path(Paths.Dashboard, ':address')}"
      subtitle="{$_('Client.create_an_organization')}"
      title="{$_('Client.Browse')}"
    />
  </div>
</div>
