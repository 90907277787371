<script>
import BigNumber from 'bignumber.js';
import { writable } from 'svelte/store';
import { liveOrganizations } from '../../../stores/organizations.js';
import { contract, eth } from '../../../subeth';

import ElasticGovernanceToken from '../../../artifacts/ElasticGovernanceToken.json';
import { amountFormatter, onClickOutside } from '../../../utils.js';
import { go, Paths } from '../../../routes/index.js';
import { selectOrganization } from '../../../stores/sync.js';
import { _ } from 'svelte-i18n';

export let params = {};

let deltaLambda = '0';
let disabled = false;
let seeding = false;
let seedAmount = '1';
let summoning = false;

// Images
const jellyfish = 'images/jelly-fish-bubbles.svg';
let summonerSeedValues = writable({});

const { keys } = liveOrganizations;

$: keySet = $keys[params.id];
$: dao = liveOrganizations[keySet.daoStoreKey];
$: ecosystem = liveOrganizations[keySet.ecosystemStoreKey];

const updateBalances = async ({ id, summoners }) => {
  if (!id) return true;

  const newSeedValues = {};
  const tokenContract = await contract({
    abi: ElasticGovernanceToken.abi,
    address: $ecosystem.governanceTokenAddress,
  });

  const shareBalances = await Promise.all([
    ...summoners.map((summoner) => tokenContract.balanceOfInShares(summoner)),
    tokenContract.totalSupplyInShares(),
    $eth.provider.getBalance(id),
  ]);

  const ethBalance = BigNumber(shareBalances.pop().toString()).dividedBy(
    10 ** 18,
  );
  const lambda = BigNumber(shareBalances.pop().toString()).dividedBy(10 ** 18);

  for (let i = 0; i < summoners.length; i += 1) {
    let amount = BigNumber(0);

    if (!lambda.isZero()) {
      const percentageOfLambda = BigNumber(shareBalances[i].toString())
        .dividedBy(10 ** 18)
        .dividedBy(lambda)
        .dp(18);
      amount = ethBalance.multipliedBy(percentageOfLambda);
    }
    newSeedValues[summoners[i]] = amountFormatter({
      amount,
      approximatePrefix: '',
      maxDigits: 5,
    });
  }

  summonerSeedValues.set({ ...newSeedValues });
  return true;
};

$: isSummoner = (({ summoners }, { address }) =>
  (summoners || [])
    .map((summoner) => summoner.toLowerCase())
    .includes((address || '').toLowerCase()))($dao, $eth);

let summonDisabledOverride = false;

$: updateBalances($dao);
$: summonDisabled = ((values) =>
  summonDisabledOverride ||
  Object.values(values)
    .reduce((sum, val) => sum.plus(val), BigNumber(0))
    .isEqualTo(0))($summonerSeedValues);

const plantSeeds = async () => {
  disabled = true;

  await dao.elasticDAO.seedSummoning({
    value: seedAmount,
  });
  await updateBalances($dao);
  seeding = false;
  disabled = false;
};

const summon = async () => {
  summonDisabledOverride = true;
  await dao.elasticDAO.summon(deltaLambda);
  summonDisabledOverride = false;
  await selectOrganization(params.id);
  go(Paths.Dashboard, params.id);
  summoning = false;
  summonDisabledOverride = false;
};

const handleOnClickOutside = (event) => {
  summoning = false;
  seeding = false;
};
</script>

<div class="elastic-settings fade-in">
  <section class="section">
    <div class="section-header">
      <h1>
        {#if $dao.summoned}
          {$_('Summon.Summoning_History')}
        {:else}{$_('Summon.Complete_summoning_of_your_elastic_DAO')}{/if}
      </h1>
      {#if isSummoner && !$dao.summoned}
        {#if !seeding && !summoning}
          <div class="action-container">
            <button
              class="button action-button"
              disabled=""
              on:click="{() => (seeding = true)}"
            >
              <i class="fas fa-seedling mr-3"></i>
              {$_('Summon.Seed')}
            </button>
            <button
              class="button action-button"
              disabled="{summonDisabled}"
              on:click="{() => (summoning = true)}"
            >
              <i class="fas fa-hat-wizard mr-3"></i>
              {$_('Summon.Summon')}
            </button>
          </div>
        {/if}
        {#if seeding}
          <div
            class="action-container is-inline-flex is-align-items-center"
            use:onClickOutside
            on:click_outside="{handleOnClickOutside}"
          >
            <h5 class="mr-3">{$_('Summon.How_much')}</h5>
            <div class="control is-expanded mr-3">
              <input
                class="w-36 has-text-right"
                type="text"
                bind:value="{seedAmount}"
              />
            </div>
            <button
              class="button action-button waiting"
              disabled="{disabled}"
              on:click="{plantSeeds}"
            >
              {#if !disabled}
                <i class="fas fa-seedling mr-3"></i>
                Plant
              {:else}
                <i class="fas fa-spinner fa-spin mr-3"></i>
                {$_('Summon.Planting')}
              {/if}
            </button>
          </div>
        {/if}
        {#if summoning}
          <div
            class="action-container is-inline-flex is-align-items-center"
            use:onClickOutside
            on:click_outside="{handleOnClickOutside}"
          >
            <h5 class="mr-3">{$_('Summon.Base_Summoner_Shares')}</h5>
            <div class="control is-expanded mr-3">
              <input
                class="w-36 has-text-right"
                type="text"
                bind:value="{deltaLambda}"
              />
            </div>
            <button
              class="button action-button waiting"
              disabled="{summonDisabled}"
              on:click="{summon}"
            >
              {#if !summonDisabled}
                <i class="fas fa-hat-wizard mr-3"></i>
                {$_('Summon.Summon')}
              {:else}
                <i class="fas fa-spinner fa-spin mr-3"></i>
                {$_('Summon.Summoning')}
              {/if}
            </button>
          </div>
        {/if}
      {/if}
    </div>

    <div class="columns">
      <div class="column is-three-fifths-desktop is-mobile">
        <h2>{$_('Summon.Summoners')}</h2>
        <div class="columns is-multiline is-mobile">
          <div class="column">
            <table class="table">
              <thead>
                <tr>
                  <th><abbr title="Address"></abbr>{$_('Summon.Address')}</th>
                  {#if !$dao.summoned}
                    <th>
                      <abbr
                        title="Seed Amount"
                      ></abbr>{$_('Summon.Seed_Amount')}
                    </th>
                  {/if}
                </tr>
              </thead>
              <tbody>
                {#each Object.keys($summonerSeedValues) as summoner}
                  <tr>
                    <td>{summoner}</td>
                    {#if !$dao.summoned}
                      <td>{$summonerSeedValues[summoner]} ETH</td>
                    {/if}
                  </tr>
                {/each}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="column is-mobile">
        {#if $dao.summoned}
          <p class="blurb">{$_('Summon.historical_record_of')}</p>
        {:else}
          <p class="blurb">{$_('Summon.Seed_and_summon')}</p>

          <p class="blurb mt-5 mb-5">{$_('Summon.Upon_summoning')}</p>
        {/if}

        <img
          class="blurb-illustration is-hidden-mobile"
          src="{jellyfish}"
          alt="create organization"
        />
      </div>
    </div>
  </section>
</div>
