const API_URL = 'https://hub.snapshot.page/api';

export const getProposals = async (space) => {
  const url = `${API_URL}/${space}/proposals`;
  const response = await fetch(url);
  const proposals = await response.json();

  return proposals;
};

export const getVoteResults = async (space, proposalId) => {
  const url = `${API_URL}/${space}/proposal/${proposalId}`;
  const response = await fetch(url);
  const votes = await response.json();

  return votes;
};

export const publish = async (proposal) => {
  const url = `${API_URL}/message`;
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(proposal),
  });
  const publishedProposal = await response.json();

  return publishedProposal;
};
