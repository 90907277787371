import BigNumber from 'bignumber.js';
// import WalletConnectProvider from '@walletconnect/web3-provider';

import { ethers } from 'ethers';
import { get } from 'svelte/store';

import displayNotification from '../notifications';

import { erc20 as erc20Abi } from './abis';
import { allowances, eth } from './writables';
import { functionKey } from './utils';
import { observableContract } from './contracts';
import { registerConnection, resetConnection } from './connection';
import { subject } from './observables';
import { trackEthBalance } from './lifecycle';

export { allowances, balances, eth } from './writables';
export { balanceKey, functionKey } from './utils';
export { bumpLifecycle } from './lifecycle';
export { defaultProvider } from './connection';
export { subject } from './observables';

export const erc20 = erc20Abi;

const Web3Modal = window.Web3Modal.default;
const providerOptions = {
  // walletconnect: {
  //   package: WalletConnectProvider, // required
  //   options: {
  //     rpc: {
  //       1: 'http://rpc.archivenode.io/1i3jfwuzh6pbyk90geln824scx5vr7dm',
  //     },
  //   },
  // },
};

const web3Modal = new Web3Modal({
  network: 'mainnet', // optional
  cacheProvider: false, // optional
  providerOptions, // required
});

// CONNECTION MANAGEMENT

export const connectWeb3 = async () => {
  try {
    resetConnection();

    const web3 = await web3Modal.connect();

    if (!web3) {
      return;
    }

    registerConnection(web3);
  } catch (e) {
    console.error('ERROR CONNECTION TO WEB3', e);
    resetConnection();
  }
};

export const disconnectWeb3 = async () => {
  resetConnection();
};

// SUBSPACE(ISH)

export const contract = ({ abi, address, fallback }) =>
  observableContract({ abi, address, fallback }); // async
export const trackBalance = async (address, tokenAddress) => {
  const ethData = get(eth);
  let walletAddress = address;

  if (!walletAddress) {
    if (!ethData.address) {
      throw new Error(
        'stores/eth#trackBalance - an wallet must be connected or a wallet address passed as the first argument',
      );
    }
    walletAddress = ethData.address;
  }

  if (tokenAddress && tokenAddress !== ethers.constants.AddressZero) {
    const tokenContract = await contract({ abi: erc20, address: tokenAddress });
    return tokenContract.trackBalance(walletAddress);
  }

  return trackEthBalance(walletAddress);
};
export const trackBlock = async () => subject('block');
export const trackBlockNumber = async () => subject('blockNumber');
export const trackGasPrice = async () => subject('gasPrice');

// Shortcuts

export const approve = async (address, spender, amount) => {
  const erc20Contract = await contract({ address });
  const { hash } = await erc20Contract.approve(spender, amount);
  const { emitter } = displayNotification({ hash });
  const symbol = await erc20Contract.symbol();
  let currentBlockNumber;

  await new Promise((resolve) =>
    emitter.on('txConfirmed', ({ blockNumber }) => {
      currentBlockNumber = blockNumber;
      resolve();
      return { message: `${symbol} unlocked`, type: 'success' };
    }),
  );

  const decimals = await erc20Contract.decimals();
  const updates = {};
  const args = [get(eth).address, spender];
  const key = functionKey(address, 'allowance', args);

  updates[key] = BigNumber(amount.toString()).dividedBy(10 ** decimals);
  allowances.set({ ...get(allowances), ...updates });
  const lastBlock = get(eth).currentBlockNumber;
  if (currentBlockNumber > lastBlock) {
    eth.set({ ...get(eth), currentBlockNumber });
  }
};

export const approveMax = async (address, spender) => {
  await approve(address, spender, ethers.constants.MaxUint256);
};
