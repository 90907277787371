<script>
import { isAddress } from '@pie-dao/utils';

import AddressCards from '../../shared/AddressCards.svelte';
import { modules } from '../../../stores/modules.js';
import { ModuleMappings, path, Paths } from '../../../routes/index.js';
import sdk from '../../../config/sdk.js';
import { swapKV } from '../../../utils.js';

export let params = {};

const ReverseModuleMappings = swapKV(ModuleMappings);

const nameGenerator = (address) =>
  modules[address].name.replace(/Module/, '').replace(/([A-Z])/g, ' $1');

const pathGenerator = (part, id) => {
  if (part === ':name') {
    return ReverseModuleMappings[modules[id].name];
  }
  return part;
};

$: addresses = ((target) => {
  if (target) {
    return;
  }
  return Object.keys(modules).filter((id) => isAddress(id.split('|')[0]));
})(params.name);
</script>

{#if params.moduleId}
  <svelte:component
    this="{params.moduleComponent}"
    params="{params}"
    sdk="{sdk}"
    on:routeEvent
  />
{:else}
  <div class="client fade-in">
    <div class="container is-max-desktop">
      <AddressCards
        addresses="{addresses}"
        nameGenerator="{nameGenerator}"
        params="{params}"
        path="{path(Paths.Modules, params.id, ':name')}"
        pathGenerator="{pathGenerator}"
        title="Select an installed module"
      />
    </div>
  </div>
{/if}
