import { ethers } from 'ethers';
import { get, writable } from 'svelte/store';
import { isAddress } from '@pie-dao/utils';

export const addSummoner = (summoner) => {
  const { summoned, summoners } = get(organization);

  if (!summoned) {
    organization.set({
      ...get(organization),
      summoners: summoners.concat(summoner),
    });
  }
};

export const blankOrganization = {
  id: undefined,
  dao: {},
  ecosystem: {
    configuratorAddress: ethers.constants.AddressZero,
    daoAddress: ethers.constants.AddressZero,
    daoModelAddress: ethers.constants.AddressZero,
    ecosystemModelAddress: ethers.constants.AddressZero,
    elasticModuleModelAddress: ethers.constants.AddressZero,
    governanceTokenAddress: ethers.constants.AddressZero,
    registratorAddress: ethers.constants.AddressZero,
    tokenHolderModelAddress: ethers.constants.AddressZero,
    tokenModelAddress: ethers.constants.AddressZero,
  },
  name: undefined,
  numberOfSummoners: 0,
  subdomain: undefined,
  summoned: false,
  summoners: [''],
  token: {
    id: undefined,
    eByL: '1',
    elasticity: '0.03',
    k: '100',
    lambda: undefined,
    m: undefined,
    maxLambdaPurchase: '1',
    maxVotingLambda: '1',
    name: undefined,
    numberOfTokenHolders: 0,
    symbol: undefined,
  },
};

export const formatForDeploy = () => {
  const { name, summoners, token } = get(organization);

  const {
    eByL,
    elasticity,
    k,
    maxLambdaPurchase,
    maxVotingLambda,
    symbol,
  } = token;

  const sanitizedSummoners = Array.from(new Set(validSummoners(summoners)));

  return [
    sanitizedSummoners,
    name,
    token.name,
    symbol,
    eByL,
    elasticity,
    k,
    maxLambdaPurchase,
    maxVotingLambda,
  ];
};

export const organization = writable({ ...blankOrganization });

export const removeSummoner = (_summoner) => {
  const org = get(organization);

  if (!org.summoned) {
    let summoners = org.summoners.filter((summoner) => summoner !== _summoner);

    if (summoners.length === 0) {
      summoners = [''];
    }

    organization.set({ ...get(organization), summoners });
  }
};

export const resetOrganization = () => {
  organization.set({ ...blankOrganization });
};

export const validForDeploy = () => {
  const { id, name, summoners, token } = get(organization);

  const { eByL, elasticity, k, maxLambdaPurchase, symbol } = token;

  return (
    !id &&
    !!eByL &&
    !!elasticity &&
    !!k &&
    !!maxLambdaPurchase &&
    !!name &&
    validSummoners(summoners).length > 0 &&
    !!token.name &&
    !!symbol
  );
};

export const validSummoners = (summoners) =>
  summoners.filter((summoner) => isAddress(summoner));
