<script>
import { _ } from 'svelte-i18n';

export let params = {};

// Images
const jellyfish = 'images/jelly-fish-bubbles.svg';

const { keys } = liveOrganizations;

$: keySet = $keys[params.id];
$: dao = liveOrganizations[keySet.daoStoreKey];
$: token = liveOrganizations[keySet.tokenStoreKey];
</script>

<div class="elastic-settings fade-in">
  <section class="section">
    <div class="section-header">
      <h1 class="mb-3">{$_('DAO.Setting')}</h1>

      <!-- <button class="button action-button" disabled="">
        <i class="fas fa-pencil-alt mr-3"></i>

        {$_("DAO.update")}
      </button> -->
    </div>

    <div class="columns">
      <div class="column is-one-quarter-desktop is-mobile">
        <div class="section-content">
          <h5>{$_('DAO.Organization_Name')}</h5>
          <div class="control is-expanded">
            <input type="text" name="name" bind:value="{$dao.name}" />
          </div>
        </div>

        <div class="section-content">
          <h5>{$_('DAO.Token_Symbol')}</h5>

          <div class="control is-expanded">
            <input type="text" name="name" bind:value="{$token.symbol}" />
          </div>
        </div>
      </div>

      <div class="column is-one-quarter-desktop is-mobile">
        <div class="section-content">
          <h5>{$_('DAO.Token_Name')}</h5>
          <div class="control is-expanded">
            <input type="text" name="name" bind:value="{$token.name}" />
          </div>
        </div>
      </div>

      <div class="column is-mobile">
        <p class="blurb">{$_('DAO.Create_your_own_organization')}</p>
        <img
          class="blurb-illustration is-hidden-mobile"
          src="{jellyfish}"
          alt="create organization"
        />
      </div>
    </div>
  </section>
</div>
